@use "../global" as g;

.button {
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  padding: 24px 36px;
  position: relative;
  transition: .2s;

  @include g.media-query-sp {
    font-size: 1.6rem;
  }

  &-wrap {
    text-align: center;

    &--multiple {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 20px;
      margin: 0 auto;
    }
  }
}

.button-default {
  border-radius: 8px;
  background: #F89235;
  line-height: 1;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20) inset;
  padding: 32px 24px 32px 40px;

  @include g.media-query-sp {
    padding: 20px;
  }

  &.icon-download {

    &::after {
      margin-left: 20px;

      @include g.media-query-sp {
        margin-left: 10px;
      }
    }
  }
}

.button--primary {

  &::after {
    content: '';
    background: url(../img/icon_arrow.svg) no-repeat;
    background-size: contain;
    width: 13px;
    height: 20px;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);

    @include g.media-query-sp {
      width: 10px;
      height: 16px;
      right: 10px;
    }
  }
}


.button--secondary {
  border-radius: 8px;
  background: linear-gradient(180deg, #25C196 0%, #008A63 100%);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.30) inset;
}