@use "../global" as g;

.inner {
  max-width: calc(1200px + (20px * 2));
  margin: 0 auto;
  padding: 0 20px;

  &--thin {
    max-width: calc(1040px + (20px * 2));
  }
}