@charset "UTF-8";
@font-face {
  font-display: swap;
  font-weight: 500;
  font-style: normal;
  font-family: "Zen Kaku Gothic New";
  src: url("../fonts/ZenKakuGothicNew-Medium.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-weight: 700;
  font-style: normal;
  font-family: "Zen Kaku Gothic New";
  src: url("../fonts/ZenKakuGothicNew-Bold.ttf") format("truetype");
}
/*base*/
img {
  vertical-align: middle;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  line-height: 1.5;
}

/*reset*/
/* Box sizing rules */
/* Box sizingの定義 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* tableの余白削除 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Remove default padding */
/* デフォルトのpaddingを削除 */
ul,
ol {
  padding: 0;
}

/* Remove default margin */
/* デフォルトのmarginを削除 */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
/* bodyのデフォルトを定義 */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
/* class属性を持つul、ol要素のリストスタイルを削除 */
ul[class],
li,
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
/* classを持たない要素はデフォルトのスタイルを取得 */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
/* img要素の扱いを簡単にする */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
/* article要素内の要素に自然な流れとリズムを定義 */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
/* inputやbuttonなどのフォントは継承を定義 */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
/* 見たくない人用に、すべてのアニメーションとトランジションを削除 */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
html {
  font-size: 62.5%;
}

body {
  font-family: "Zen Kaku Gothic New", "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.8;
  color: #666;
}
@media screen and (max-width: 767px) {
  body {
    font-size: 1.2rem;
  }
}

img {
  height: auto;
}

a {
  transition: opacity 0.1s ease;
}

@media (hover: hover) {
  a:hover {
    opacity: 0.6;
  }
  .list-gnav-item:hover {
    color: #00B2D5;
    opacity: 1;
  }
}
.inner {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
}
.inner--thin {
  max-width: 1080px;
}

.button {
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  padding: 24px 36px;
  position: relative;
  transition: 0.2s;
}
@media screen and (max-width: 767px) {
  .button {
    font-size: 1.6rem;
  }
}
.button-wrap {
  text-align: center;
}
.button-wrap--multiple {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
}

.button-default {
  border-radius: 8px;
  background: #F89235;
  line-height: 1;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2) inset;
  padding: 32px 24px 32px 40px;
}
@media screen and (max-width: 767px) {
  .button-default {
    padding: 20px;
  }
}
.button-default.icon-download::after {
  margin-left: 20px;
}
@media screen and (max-width: 767px) {
  .button-default.icon-download::after {
    margin-left: 10px;
  }
}

.button--primary::after {
  content: "";
  background: url(../img/icon_arrow.svg) no-repeat;
  background-size: contain;
  width: 13px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
@media screen and (max-width: 767px) {
  .button--primary::after {
    width: 10px;
    height: 16px;
    right: 10px;
  }
}

.button--secondary {
  border-radius: 8px;
  background: linear-gradient(180deg, #25C196 0%, #008A63 100%);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3) inset;
}

@media screen and (min-width: 768px) {
  .column {
    display: flex;
    flex-wrap: wrap;
  }
  .column-two {
    gap: 40px;
  }
  .column-two .column-item {
    width: calc((100% - 40px) / 2);
  }
  .column-three {
    gap: 40px;
  }
  .column-three .column-item {
    width: calc((100% - 80px) / 3);
  }
  .column-four {
    gap: 40px;
  }
  .column-four .column-item {
    width: calc((100% - 120px) / 4);
  }
}
.column-image {
  width: 100%;
}
.column-description {
  font-size: 1.6rem;
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}
@media screen and (min-width: 768px) {
  .header {
    height: 80px;
  }
}
.header .inner {
  max-width: none;
  padding: 0 40px;
  margin: 0 0 0 auto;
}

.list-gnav {
  display: flex;
}
@media screen and (max-width: 767px) {
  .list-gnav {
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    width: 96%;
    height: 100%;
    padding-top: 48px;
    position: absolute;
    top: 0;
    right: -100%;
    transition: transform 0.4s ease;
    transform: translateX(0%);
  }
}
.is-active .list-gnav {
  transform: translateX(-100%);
}
@media screen and (max-width: 767px) {
  .list-gnav-wrap {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.2s ease;
    opacity: 0;
    pointer-events: none;
  }
  .list-gnav-wrap.is-active {
    opacity: 1;
    pointer-events: initial;
  }
}
.list-gnav-item {
  font-weight: 700;
  padding: 16px;
  color: #333;
  transition: color 0.1s;
}
.list-gnav-button {
  position: fixed;
  top: 0;
  right: 4px;
  width: 48px;
  height: 48px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 999;
}
@media screen and (min-width: 768px) {
  .list-gnav-button {
    display: none;
  }
}
.list-gnav-button > span {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 2px;
  background-color: black;
  transform: translate(-50%, -50%);
}
.list-gnav-button > span::before, .list-gnav-button > span::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 2px;
  background-color: black;
  transform: translate(-50%, -50%);
  transform-origin: center center;
  transition: top 0.3s ease, transform 0.3s ease;
}
.list-gnav-button > span::before {
  top: calc(50% - 8px);
}
.list-gnav-button > span::after {
  top: calc(50% + 8px);
}
.list-gnav-button.is-active > span {
  background-color: transparent;
}
.list-gnav-button.is-active > span::before, .list-gnav-button.is-active > span::after {
  top: 50%;
  width: 30px;
}
.list-gnav-button.is-active > span::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.list-gnav-button.is-active > span::after {
  transform: translate(-50%, -50%) rotate(45deg);
}

body.is-menu-active {
  height: 100%;
  overflow: hidden;
}

.footer {
  margin-top: 120px;
  padding: 40px 20px;
  background-color: #00B2D5;
  color: #fff;
  font-size: 1.2rem;
  line-height: 1.8;
}
@media screen and (max-width: 767px) {
  .footer {
    margin-top: 80px;
  }
}
.footer .inner {
  max-width: 550px;
}
.footer-heading {
  font-weight: 700;
  font-size: inherit;
}
.footer .icon-mail::before {
  transform: translateY(3px);
  margin-right: 8px;
}

.icon::before, .icon::after {
  font-family: "Material Symbols Outlined";
}

.icon-download::after {
  content: "";
  width: 16px;
  height: 16px;
  background: url(../img/icon_download.svg) no-repeat;
  background-size: contain;
  display: inline-block;
}
@media screen and (max-width: 767px) {
  .icon-download::after {
    width: 13px;
    height: 13px;
  }
}

.icon-mail::before {
  content: "";
  width: 13px;
  height: 13px;
  background: url(../img/icon_mail.svg) no-repeat;
  background-size: contain;
  display: inline-block;
}

.icon-keyboard-double-arrow-right::after {
  content: "\eac9";
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 20;
}

.is-show {
  animation: fadeUp 1s forwards;
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(16px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes blur {
  0% {
    opacity: 0;
    transform: scale(0.7);
    filter: blur(40px);
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: scale(1);
    filter: blur(0px);
  }
}
@keyframes bg_gradient_01 {
  0% {
    opacity: 0;
    background-color: #fff;
  }
  100% {
    opacity: 0.7;
    background-color: #00B2D5;
  }
}
@keyframes bg_gradient_02 {
  0% {
    filter: blur(40px);
  }
  100% {
    filter: blur(0px);
  }
}
.text-default + .text-default {
  margin-top: 1em;
}

.main {
  overflow: hidden;
  margin-top: 80px;
}
@media screen and (max-width: 767px) {
  .main {
    margin-top: 48px;
  }
}

.kv {
  background: url(../img/webp/classroom.webp);
  background-size: cover;
  background-position: center center;
  position: relative;
}
.kv::before {
  content: "";
  opacity: 0.7;
  background: #00B2D5;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.heading-page {
  text-align: center;
  padding: 38px 0;
  position: relative;
  z-index: 1;
  opacity: 0;
  animation: 1.2s blur ease 0.4s forwards;
  will-change: contents;
}
.page-top .heading-page {
  padding: 238px 0;
}
@media screen and (max-width: 767px) {
  .page-top .heading-page {
    padding: 175px 0;
    margin: 0 auto;
    width: 240px;
  }
}
.heading-page img {
  display: inline;
}

.section,
.article,
.banner-block,
.single--list-supporters {
  padding: clamp(80px, 21.3333333333vw, 120px) 0;
}

.article-release {
  display: flex;
  align-items: flex-start;
  gap: 60px;
}
@media screen and (max-width: 767px) {
  .article-release {
    flex-direction: column;
    gap: 40px;
  }
}
.article-release-image {
  display: block;
  box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.2);
  width: clamp(184px, 49vw, 368px);
  margin: 0 auto;
}
.article-release-body {
  flex: 1;
}
.article-release-tag {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 2px 16px;
  color: #00B2D5;
  border-radius: 4px;
  border: 1px solid #00B2D5;
}
.article-release-date {
  display: inline-block;
  font-size: 1.2rem;
  margin-left: 12px;
}
.article-release-heading {
  font-size: 2.4rem;
  line-height: 1.5;
  margin: 24px 0;
}
@media screen and (max-width: 767px) {
  .article-release-heading {
    font-size: 2rem;
    margin: 20px 0;
  }
}
.article-release-text + .article-release-text {
  margin-top: 1em;
}
.article-release-link {
  margin-top: 32px;
}

.heading-primary {
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  color: #00B2D5;
}
@media screen and (max-width: 767px) {
  .heading-primary {
    font-size: 2.4rem;
  }
}
.heading-primary--en {
  letter-spacing: 0.2em;
  color: #007D9E;
}
.heading-primary-wrap {
  margin-bottom: 120px;
}
@media screen and (max-width: 767px) {
  .heading-primary-wrap {
    margin-bottom: 80px;
  }
}

.heading-secondary {
  font-size: 2rem;
  font-weight: 700;
  color: #333;
  letter-spacing: 0.4em;
  margin-right: -0.4em;
  display: inline-block;
  background-color: #fff;
  padding: 0 40px;
}
@media screen and (max-width: 767px) {
  .heading-secondary {
    font-size: 1.6rem;
    margin: 0 32px;
    letter-spacing: 0.2em;
    margin: -0.2em;
  }
}
.heading-secondary::before {
  content: "";
  width: 100%;
  height: 0;
  position: absolute;
  top: 50%;
  left: 0;
  border-top: 1px dotted #333;
  z-index: -1;
}
.heading-secondary-wrap {
  position: relative;
  text-align: center;
}
.heading-secondary-lead {
  color: #333;
  text-align: center;
  margin: 40px 0;
}

.heading--dot {
  position: relative;
}
.heading--dot::before, .heading--dot::after {
  content: "";
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  width: 56px;
  height: 1em;
  vertical-align: bottom;
  margin: 0 32px 4px;
}
@media screen and (max-width: 767px) {
  .heading--dot::before, .heading--dot::after {
    width: 42px;
  }
}
.heading--dot::before {
  background-image: url(../img/dot-left.png);
  margin: 0 24px 4px 0;
}
.heading--dot::after {
  background-image: url(../img/dot-right.png);
  margin: 0 0 4px 24px;
}

.heading-lead {
  text-align: center;
  line-height: 1.8;
  margin-top: 2em;
  color: #333;
}
.heading-lead.kotoba {
  margin-top: 1em;
}
@media screen and (max-width: 767px) {
  .heading-lead {
    font-size: 12px;
  }
}

.section {
  position: relative;
}

.section--bg-light {
  background-color: #F2F9F8;
}

.section--theme {
  background-color: #00B2D5;
  color: #fff;
  text-align: center;
}
.section--theme .heading-primary {
  color: #fff;
  margin-bottom: 32px;
}

.section--action .list-card {
  margin-top: 80px;
}
@media screen and (max-width: 767px) {
  .section--action .list-card {
    margin-top: 40px;
  }
}

.section--supportedBy {
  padding-top: 0;
}

.section--voice .inner,
.section--supporters-simple .inner {
  max-width: 1192px;
}

.section--supporters-simple .list-supporters-none-image {
  margin-top: 120px;
}

.scroll-in-person {
  opacity: 0;
  position: absolute;
  top: 245px;
}
.scroll-in-person--01 {
  left: calc(50% - 574px);
  animation-delay: 0ms;
}
.scroll-in-person--02 {
  left: calc(50% - 706px);
  animation-delay: 500ms;
  animation-duration: 1.4s;
}
.scroll-in-person--03 {
  left: calc(50% - 838px);
  animation-delay: 1000ms;
  animation-duration: 2.4s;
}
.scroll-in-person--04 {
  left: calc(50% + 454px);
  animation-delay: 0ms;
}
.scroll-in-person--05 {
  left: calc(50% + 586px);
  animation-delay: 500ms;
  animation-duration: 1.4s;
}
.scroll-in-person--06 {
  left: calc(50% + 718px);
  animation-delay: 1000ms;
  animation-duration: 2.4s;
}

.section--lead .graph-work-balance {
  margin-top: 120px;
}
@media screen and (max-width: 767px) {
  .section--lead .graph-work-balance {
    margin-top: 80px;
  }
}

.banner-block .inner {
  display: flex;
  justify-content: center;
  gap: 80px;
}
@media screen and (max-width: 767px) {
  .banner-block .inner {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
}

.movie-wrap {
  aspect-ratio: 16/9;
  display: flex;
}

.movie-body {
  width: 100%;
  height: 100%;
  border: none;
}

.list-movie {
  display: flex;
  gap: 80px;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .list-movie {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 767px) {
  .list-movie {
    flex-direction: column;
    gap: 40px;
  }
}
.list-movie-item {
  height: auto;
}
@media screen and (min-width: 768px) {
  .list-movie-item {
    width: calc((100% - 80px) / 2);
  }
}

.list-card {
  display: flex;
  gap: 48px;
}
@media screen and (max-width: 767px) {
  .list-card {
    flex-direction: column;
  }
}
@media screen and (min-width: 768px) {
  .list-card-item {
    width: calc((100% - 96px) / 3);
  }
}
.list-card-image {
  display: block;
  overflow: hidden;
  border-radius: 10px;
}
.list-card-image source,
.list-card-image img {
  width: 100%;
}
.list-card-heading {
  border-left: 8px solid #00B2D5;
  padding-left: 12px;
  min-height: 72px;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
  align-items: center;
  margin-top: 24px;
}
@media screen and (max-width: 767px) {
  .list-card-heading {
    font-size: 2rem;
    margin-top: 20px;
  }
}
.list-card-text {
  line-height: 1.8;
  margin-top: 24px;
}
@media screen and (max-width: 767px) {
  .list-card-text {
    margin-top: 20px;
  }
}

.article-gypsophila {
  max-width: 1000px;
  background: url(../img/webp/bg_paturn_01.webp) no-repeat;
  background-size: cover;
  background-position: center top;
  border-radius: 20px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  margin: 120px auto 0;
  padding: 80px 20px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .article-gypsophila {
    margin-top: 80px;
    padding: 40px 20px;
  }
}
.article-gypsophila-heading {
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 24px;
}
@media screen and (max-width: 767px) {
  .article-gypsophila-heading {
    font-size: 2rem;
    margin-bottom: 20px;
    line-height: 1.5;
  }
}
.article-gypsophila-text {
  line-height: 1.8;
}
.article-gypsophila-image {
  display: block;
  width: 182px;
  margin: 40px auto 0;
}
@media screen and (max-width: 767px) {
  .article-gypsophila-image {
    width: 137px;
  }
}

.list-supporters {
  display: flex;
  flex-wrap: wrap;
  gap: clamp(32px, 6vw, 96px) 24px;
  text-align: center;
  color: #333;
}
.list-supporters-item {
  width: calc((100% - 48px) / 3);
}
@media screen and (max-width: 767px) {
  .list-supporters-item {
    width: calc((100% - 24px) / 2);
  }
}
.list-supporters-image {
  display: block;
  width: 200px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .list-supporters-image {
    width: 150px;
  }
}
.list-supporters-name {
  font-size: 2.4rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1;
  margin: 24px 0;
}
@media screen and (max-width: 767px) {
  .list-supporters-name {
    font-size: 2rem;
    margin: 20px 0;
  }
}
.list-supporters-title {
  font-size: 1.2rem;
}

.list-sns {
  display: flex;
  gap: 40px;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .list-sns {
    gap: 20px;
  }
}
@media screen and (max-width: 767px) {
  .list-sns-item {
    width: 40px;
  }
}
.list-sns-item a {
  border-radius: 50%;
  overflow: hidden;
}

.list-logo {
  display: flex;
  flex-wrap: wrap;
  margin: 40px auto 0;
}
@media screen and (min-width: 768px) {
  .list-logo {
    gap: 48px;
  }
}
@media screen and (max-width: 767px) {
  .list-logo {
    max-width: 258px;
    flex-direction: column;
  }
}
@media screen and (min-width: 768px) {
  .list-logo-item {
    width: calc((100% - 96px) / 3);
  }
}

.graph-work-balance {
  display: block;
}
.graph-work-balance img {
  width: 100%;
}

.list-kotoba {
  display: flex;
  flex-wrap: wrap;
  gap: 48px;
}
@media screen and (max-width: 767px) {
  .list-kotoba {
    gap: 40px;
  }
}
.list-kotoba-item {
  border-radius: 12px;
  background: #FFF;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .list-kotoba-item {
    width: calc((100% - 48px) / 2);
  }
}
.list-kotoba-heading {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
  color: #00B2D5;
}
@media screen and (max-width: 767px) {
  .list-kotoba-heading {
    font-size: 1.6rem;
  }
}
.list-kotoba-text {
  line-height: 1.5;
  margin-top: 24px;
}
@media screen and (max-width: 767px) {
  .list-kotoba-text {
    margin-top: 20px;
  }
}

.list-supporters-none-image {
  display: flex;
  flex-wrap: wrap;
  gap: 48px;
  color: #333;
}
@media screen and (max-width: 767px) {
  .list-supporters-none-image {
    gap: 32px 24px;
  }
}
.list-supporters-none-image-item {
  width: calc((100% - 144px) / 4);
}
@media screen and (max-width: 767px) {
  .list-supporters-none-image-item {
    width: calc((100% - 24px) / 2);
  }
}
.list-supporters-none-image-name {
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .list-supporters-none-image-name {
    font-size: 1.6rem;
  }
}
.list-supporters-none-image-title {
  margin-top: 20px;
  font-size: 1.2rem;
  line-height: 1.5;
}
@media screen and (max-width: 767px) {
  .list-supporters-none-image-title {
    margin-top: 16px;
  }
}

@media screen and (min-width: 768px) {
  /* pc非表示 */
  .u-hidden-pc {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  /* sp非表示 */
  .u-hidden-sp {
    display: none;
  }
}