@use "../global" as g;

.column {

  @include g.media-query-pc {
    display: flex;
    flex-wrap: wrap;

    &-two {
      gap: 40px;

      .column-item {
        width: calc((100% - 40px) / 2);
      }
    }

    &-three {
      gap: 40px;

      .column-item {
        width: calc((100% - 80px) / 3);
      }
    }

    &-four {
      gap: 40px;

      .column-item {
        width: calc((100% - 120px) / 4);
      }
    }
  }

  &-image {
    width: 100%;
  }


  &-description {
    font-size: 1.6rem;
  }
}
