@use "../global" as g;

html {
  font-size: 62.5%;// font-sizeの10pxを1remにするため
}

body {
  font-family: g.$font-base;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.8;
  color: #666;

  @include g.media-query-sp {
    font-size: 1.2rem;
  }
}

img {
  height: auto;
}

a {
  transition: opacity .1s ease;
}

@media (hover: hover) {

  a:hover {
    opacity: .6;
  }

  .list-gnav-item:hover {
    color: #00B2D5;
    opacity: 1;
  }
}