@use "../global" as g;

// icon
.icon {

  &::before,
  &::after {
    font-family: "Material Symbols Outlined";
  }
}

.icon-download {

  &::after {
    content: '';
    width: 16px;
    height: 16px;
    background: url(../img/icon_download.svg) no-repeat;
    background-size: contain;
    display: inline-block;

    @include g.media-query-sp {
      width: 13px;
      height: 13px;
    }
  }
}

.icon-mail {

  &::before {
    content: '';
    width: 13px;
    height: 13px;
    background: url(../img/icon_mail.svg) no-repeat;
    background-size: contain;
    display: inline-block;
  }
}

.icon-keyboard-double-arrow-right {

  &::after {
    content: '\eac9';
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 20
  }
}

.is-show {
  animation: fadeUp 1s forwards;
}

@keyframes fadeUp{
  0% {
    opacity:0;
    transform: translateY(16px);
  }

  100% {
    opacity:1;
    transform: translateY(0);
  }
}

@keyframes blur {
  0% {
    opacity: 0;
    transform: scale(.7);
    filter: blur(40px);
  }

  10% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: scale(1);
    filter: blur(0px);
  }
}

@keyframes bg_gradient_01 {
  0% {
    opacity: 0;
    background-color: #fff;
  }
  100% {
    opacity: .7;
    background-color: #00B2D5;
  }
}

@keyframes bg_gradient_02 {
  0% {
    filter: blur(40px);
  }
  100% {
    filter: blur(0px);
  }
}

.text-default {

  & + & {
    margin-top: 1em;
  }
}

.main {
  overflow: hidden;
  margin-top: 80px;

  @include g.media-query-sp {
    margin-top: 48px;
  }
}

.kv {
  background: url(../img/webp/classroom.webp);
  background-size: cover;
  background-position: center center;
  position: relative;

  &::before {
    content: '';
    opacity: 0.7;
    background: #00B2D5;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
}

.heading-page {
  text-align: center;
  padding: 38px 0;
  position: relative;
  z-index: 1;
  opacity: 0;
  animation: 1.2s blur ease .4s forwards;
  will-change: contents;

  .page-top & {
    padding: 238px 0;

    @include g.media-query-sp {
      padding: 175px 0;
      margin: 0 auto;
      width: 240px;
    }
  }

  img {
    display: inline;
  }
}

.section,
.article,
.banner-block,
.single--list-supporters {
  padding: clamp(80px, calc((80 / 375) * 100vw), 120px) 0;
}

.article-release {
  display: flex;
  align-items: flex-start;
  gap: 60px;

  @include g.media-query-sp {
    flex-direction: column;
    gap: 40px;
  }

  &-image {
    display: block;
    box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.20);
    width: clamp(184px, 49vw, 368px);
    margin: 0 auto;
  }

  &-body {
    flex: 1;
  }

  &-tag {
    display: inline-block;
    font-size: 1.2rem;
    font-weight: 700;
    padding: 2px 16px;
    color: #00B2D5;
    border-radius: 4px;
    border: 1px solid #00B2D5;
  }

  &-date {
    display: inline-block;
    font-size: 1.2rem;
    margin-left: 12px;
  }

  &-heading {
    font-size: 2.4rem;
    line-height: 1.5;
    margin: 24px 0;

    @include g.media-query-sp {
      font-size: 2rem;
      margin: 20px 0;
    }
  }

  &-text {

    & + & {
      margin-top: 1em;
    }
  }

  &-link {
    margin-top: 32px;
  }
}

.heading-primary {
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  color: #00B2D5;
  
  @include g.media-query-sp {
    font-size: 2.4rem;
  }
  
  &--en {
    letter-spacing: .2em;
    color: #007D9E;
  }

  &-wrap {
    margin-bottom: 120px;

    @include g.media-query-sp {
      margin-bottom: 80px;
    }
  }
}

.heading-secondary {
  font-size: 2rem;
  font-weight: 700;
  color: #333;
  letter-spacing: .4em;
  margin-right: -.4em;
  display: inline-block;
  background-color: #fff;
  padding: 0 40px;

  @include g.media-query-sp {
    font-size: 1.6rem;
    margin: 0 32px;
    letter-spacing: .2em;
    margin: -.2em;
  }

  &::before {
    content: '';
    width: 100%;
    height: 0;
    position: absolute;
    top: 50%;
    left: 0;
    border-top: 1px dotted #333;
    z-index: -1;
  }

  &-wrap {
    position: relative;
    text-align: center;
  }

  &-lead {
    color: #333;
    text-align: center;
    margin: 40px 0;
  }
}

.heading--dot {
  position: relative;

  &::before,
  &::after {
    content: '';
    background-size: cover;
    background-repeat: no-repeat;
    display: inline-block;
    width: 56px;
    height: 1em;
    vertical-align: bottom;
    margin: 0 32px 4px;

    @include g.media-query-sp {
      width: 42px;
    }
  }

  &::before {
    background-image: url(../img/dot-left.png);
    margin: 0 24px 4px 0;
  }

  &::after {
    background-image: url(../img/dot-right.png);
    margin: 0 0 4px 24px;
  }
}

.heading-lead {
  text-align: center;
  line-height: 1.8;
  margin-top: 2em;
  color: #333;
  &.kotoba{
    margin-top: 1em;
  }
  @include g.media-query-sp {
    font-size: 12px;
  }
}

.section {
  position: relative;
}

.section--bg-light {
  background-color: #F2F9F8;
}

.section--theme {
  background-color: #00B2D5;
  color: #fff;
  text-align: center;

  .heading-primary {
    color: #fff;
    margin-bottom: 32px;
  }
}

.section--action {

  .list-card {
    margin-top: 80px;

    @include g.media-query-sp {
      margin-top: 40px;
    }
  }
}

.section--supportedBy {
  padding-top: 0;
}

.section--voice,
.section--supporters-simple {

  .inner {
    max-width: calc(1152px + (20px * 2));
  }
}

.section--supporters-simple {

  .list-supporters-none-image {
    margin-top: 120px;
  }
}

.scroll-in-person {
  opacity: 0;
  position: absolute;
  top: 245px;

  &--01 {
    left: calc(50% - calc(574px + (120px + 12px) * 0));
    animation-delay: 0ms;
  }

  &--02 {
    left: calc(50% - calc(574px + (120px + 12px) * 1));
    animation-delay: 500ms;
    animation-duration: 1.4s;
  }

  &--03 {
    left: calc(50% - calc(574px + (120px + 12px) * 2));
    animation-delay: 1000ms;
    animation-duration: 2.4s;
  }

  &--04 {
    left: calc(50% + calc(454px + (120px + 12px) * 0));
    animation-delay: 0ms;
  }

  &--05 {
    left: calc(50% + calc(454px + (120px + 12px) * 1));
    animation-delay: 500ms;
    animation-duration: 1.4s;
  }

  &--06 {
    left: calc(50% + calc(454px + (120px + 12px) * 2));
    animation-delay: 1000ms;
    animation-duration: 2.4s;
  }
}

.section--lead {

  .graph-work-balance {
    margin-top: 120px;

    @include g.media-query-sp {
      margin-top: 80px;
    }
  }
}

.banner-block {

  .inner {
    display: flex;
    justify-content: center;
    gap: 80px;

    @include g.media-query-sp {
      flex-direction: column;
      align-items: center;
      gap: 40px;
    }
  }
}

.movie-wrap {
  aspect-ratio: 16/9;
  display: flex;
}

.movie-body {
  width: 100%;
  height: 100%;
  border: none;
}

.list-movie {
  display: flex;
  gap: 80px;
  justify-content: center;

  @include g.media-query-pc {
    flex-wrap: wrap;
  }

  @include g.media-query-sp {
    flex-direction: column;
    gap: 40px;
  }

  &-item {
    height: auto;

    @include g.media-query-pc {
      width: calc((100% - 80px) / 2);
    }
  }
}

.list-card {
  display: flex;
  gap: 48px;

  @include g.media-query-sp {
    flex-direction: column;
  }

  &-item {

    @include g.media-query-pc {
      width: calc((100% - (48px * 2)) / 3);
    }
  }

  &-image {
    display: block;
    overflow: hidden;
    border-radius: 10px;

    source,
    img {
      width: 100%;
    }
  }

  &-heading {
    border-left: 8px solid #00B2D5;
    padding-left: 12px;
    min-height: 72px;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1.5;
    display: flex;
    align-items: center;
    margin-top: 24px;

    @include g.media-query-sp {
      font-size: 2rem;
      margin-top: 20px;
    }
  }

  &-text {
    line-height: 1.8;
    margin-top: 24px;

    @include g.media-query-sp {
      margin-top: 20px;
    }
  }
}

.article-gypsophila {
  max-width: 1000px;
  background: url(../img/webp/bg_paturn_01.webp)no-repeat;
  background-size: cover;
  background-position: center top;
  border-radius: 20px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.20);
  margin: 120px auto 0;
  padding: 80px 20px;
  text-align: center;

  @include g.media-query-sp {
    margin-top: 80px;
    padding: 40px 20px;
  }

  &-heading {
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 24px;

    @include g.media-query-sp {
      font-size: 2rem;
      margin-bottom: 20px;
      line-height: 1.5;
    }
  }

  &-text {
    line-height: 1.8;
  }

  &-image {
    display: block;
    width: 182px;
    margin: 40px auto 0;

    @include g.media-query-sp {
      width: 137px;
    }
  }
}

.list-supporters {
  display: flex;
  flex-wrap: wrap;
  gap: clamp(32px, 6vw, 96px) 24px;
  text-align: center;
  color: #333;

  &-item {
    width: calc((100% - (24px * 2)) / 3);

    @include g.media-query-sp {
      width: calc((100% - 24px) / 2);
    }
  }

  &-image {
    display: block;
    width: 200px;
    margin: 0 auto;

    @include g.media-query-sp {
      width: 150px;
    }
  }

  &-name {
    font-size: 2.4rem;
    font-weight: 700;
    letter-spacing: .05em;
    line-height: 1;
    margin: 24px 0;

    @include g.media-query-sp {
      font-size: 2rem;
      margin: 20px 0;
    }
  }

  &-title {
    font-size: 1.2rem;
  }
}

.list-sns {
  display: flex;
  gap: 40px;
  justify-content: center;

  @include g.media-query-sp {
    gap: 20px;
  }

  &-item {

    @include g.media-query-sp {
      width: 40px;
    }

    a {
      border-radius: 50%;
      overflow: hidden;
    }
  }
}

.list-logo {
  display: flex;
  flex-wrap: wrap;
  margin: 40px auto 0;
  
  @include g.media-query-pc {
    gap: 48px;
  }
  
  @include g.media-query-sp {
    max-width: 258px;
    flex-direction: column;
  }

  &-item {

    @include g.media-query-pc {
      width: calc((100% - (48px * 2)) / 3);
    }
  }
}

.graph-work-balance {
  display: block;

  img {
    width: 100%;
  }
}

.list-kotoba {
  display: flex;
  flex-wrap: wrap;
  gap: 48px;

  @include g.media-query-sp {
    gap: 40px;
  }

  &-item {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.20);
    padding: 20px;
    width: 100%;
    
    @include g.media-query-pc {
      width: calc((100% - 48px) / 2);
    }
  }

  &-heading {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.5;
    color: #00B2D5;

    @include g.media-query-sp {
      font-size: 1.6rem;
    }
  }

  &-text {
    line-height: 1.5;
    margin-top: 24px;

    @include g.media-query-sp {
      margin-top: 20px;
    }
  }
}

.list-supporters-none-image {
  display: flex;
  flex-wrap: wrap;
  gap: 48px;
  color: #333;

  @include g.media-query-sp {
    gap: 32px 24px;
  }

  &-item {
    width: calc((100% - (48px * 3)) / 4);

    @include g.media-query-sp {
      width: calc((100% - 24px) / 2);
    }
  }

  &-name {
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: .05em;
    line-height: 1;

    @include g.media-query-sp {
      font-size: 1.6rem;
    }
  }

  &-title {
    margin-top: 20px;
    font-size: 1.2rem;
    line-height: 1.5;

    @include g.media-query-sp {
      margin-top: 16px;
    }
  }
}