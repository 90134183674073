@use "../global" as g;

.footer {
  margin-top: 120px;
  padding: 40px 20px;
  background-color: #00B2D5;
  color: #fff;
  font-size: 1.2rem;
  line-height: 1.8;

  @include g.media-query-sp {
    margin-top: 80px;
  }

  .inner {
    max-width: 550px;
  }

  &-heading {
    font-weight: 700;
    font-size: inherit;
  }

  &-text {}

  .icon-mail {

    &::before {
      transform: translateY(3px);
      margin-right: 8px;
    }
  }
}