// color
$logo-color: #00B2D5;
$font-base-color: #666;
$font-depth-color: #333;

//breakpoints
$breakpoints-spfirst: (
  'xs': 'screen and (min-width: 480px)',
  'sm': 'screen and (min-width: 768px)',
  'md': 'screen and (min-width: 992px)',
  'lg': 'screen and (min-width: 1200px)',
) !default;
$breakpoints-pcfirst: (
  'xs': 'screen and (max-width: 479px)',
  'sm': 'screen and (max-width: 767px)',
  'md': 'screen and (max-width: 991px)',
  'lg': 'screen and (max-width: 1199px)',
) !default;

@font-face{
  font-display: swap;
  font-weight:  500;
  font-style: normal;
  font-family: 'Zen Kaku Gothic New';
  src: url("../fonts/ZenKakuGothicNew-Medium.ttf") format("truetype");
}
@font-face{
  font-display: swap;
  font-weight: 700;
  font-style: normal;
  font-family: 'Zen Kaku Gothic New';
  src: url("../fonts/ZenKakuGothicNew-Bold.ttf") format("truetype");
}

//font
$font-base: 'Zen Kaku Gothic New', 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif;