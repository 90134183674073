@use "../global" as g;

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;

  @include g.media-query-pc {
    height: 80px;
  }

  .inner {
    max-width: none;
    padding: 0 40px;
    margin: 0 0 0 auto;
  }
}

.list-gnav {
  display: flex;

  @include g.media-query-sp {
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    width: 96%;
    height: 100%;
    padding-top: 48px;
    position: absolute;
    top: 0;
    right: -100%;
    transition: transform .4s ease;
    transform: translateX(0%);
  }

  .is-active & {
    transform: translateX(-100%);
  }

  &-wrap {

    @include g.media-query-sp {
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      transition: opacity .2s ease;
      opacity: 0;
      pointer-events: none;

      &.is-active {
        opacity: 1;
        pointer-events: initial;
      }
    }
  }

  &-item {
    font-weight: 700;
    padding: 16px;
    color: #333;
    transition: color .1s;
  }

  &-button {

    @include g.media-query-pc {
      display: none;
    }

    position: fixed;
    top: 0;
    right: 4px;
    width: 48px;
    height: 48px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 999;

    > span {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 24px;
      height: 2px;
      background-color: black;
      transform: translate(-50%, -50%);

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 24px;
        height: 2px;
        background-color: black;
        transform: translate(-50%, -50%);
        transform-origin: center center;
        transition: top .3s ease, transform .3s ease;
      }

      &::before {
        top: calc(50% - 8px);
      }

      &::after {
        top: calc(50% + 8px);
      }
    }

    &.is-active {

      > span {
        background-color: transparent;

        &::before,
        &::after {
          top: 50%;
          width: 30px;
        }

        &::before {
          transform: translate(-50%, -50%) rotate(-45deg);
        }

        &::after {
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }
    }
  }
}

body.is-menu-active {
  height: 100%;
  overflow: hidden;
}